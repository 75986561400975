// extracted by mini-css-extract-plugin
export var headshot = "index-module--headshot--257b0";
export var iframeContainer = "index-module--iframeContainer--feb50";
export var line = "index-module--line--b26f5";
export var moreNews = "index-module--moreNews--b2462";
export var name = "index-module--name--adab4";
export var newsContainer = "index-module--newsContainer--8ac50";
export var pronunciation = "index-module--pronunciation--5c325";
export var shortBio = "index-module--shortBio--8da90";
export var social = "index-module--social--63cc4";
export var wrapper = "index-module--wrapper--8d8a2";