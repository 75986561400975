import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { globalHistory } from "@reach/router";

import Template from "../components/Template";
import News from "../components/News";
import NewsYAML from "../content/news.yaml";

import * as styles from "./index.module.scss";

const IndexPage = ({ }) => {
  const [numNews, setNumNews] = useState(4);
  const location = globalHistory.location;
  const pageLang = !globalHistory.location.pathname.includes("/fr")
    ? "en"
    : "fr";

  const show = (lang) => {
    if (lang === "en" && pageLang === "en") return { display: "block" };
    else if (lang === "fr" && pageLang === "fr") return { display: "block" };
    else return { display: "none" };
  };

  //const headshot_index = Math.floor(Math.random() * 4) + 1;
  const headshot_index = 1;

  return (
    <Template location={location}>
      <Helmet>
        <title>{`Charih / ${pageLang === "en" ? "Home" : "Accueil"}`}</title>
        <script
          src="https://kit.fontawesome.com/ab30a1a18b.js"
          crossorigin="anonymous"
        ></script>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/gh/jpswalsh/academicons@1/css/academicons.min.css"
        />
      </Helmet>
      <audio id="audio" src="/FrancoisCharih.mp3"></audio>
      <img
        className={styles.headshot}
        src={`/img/headshots/charih${headshot_index}.png`}
      />
      <div style={show("en")} className={styles.name}>
        François Charih (he/him/his)
      </div>
      <div style={show("fr")} className={styles.name}>
        François Charih (il/lui)
      </div>
      <div style={show("en")} className={styles.pronunciation}>
        (🇬🇧 pronunciation: <i>Fran-swa Sha-ree</i>)
      </div>
      <div style={show("fr")} className={styles.pronunciation}>
        (prononciation: <i>Fran-swa Cha-ri</i>)
      </div>
      <div className={styles.shortBio} style={show("en")}>
        PhD candidate
        in <a href="https://cu-bic.ca">cuBIC</a>/
        <a href="https://biggarlab.ca">Biggar Lab</a> @ Carleton University
        <br />
        Research scientist and co-founder @ <a href="https://nuvobio.com">NuvoBio</a>
        <br />
        Feminist, antiracist, queer, progressist
        <br />
        <b>
          In my spare times, I <a href="https://linktr.ee/itsfrancois31">write songs</a>.
        </b>
      </div>
      <div className={styles.shortBio} style={show("fr")}>
        Doctorant au <a href="https://cu-bic.ca">cuBIC</a>/
        <a href="https://biggarlab.ca">Biggar Lab</a> @ Université Carleton
        <br />
        Chercheur et co-fondateur @ <a href="https://nuvobio.com">NuvoBio</a>
        <br />
        Féministe, anti-raciste, queer, progressiste
        <br />
        <b>
          Dans mes temps libres,{" "}
          <a href="https://linktr.ee/itsfrancois31">j'écris des chansons</a>.
        </b>
      </div>
      <div className={styles.social}>
        <a href="mailto:francois@charih.ca">
          Email
          {/*<i class="fa fa-envelope fa-2x"></i>*/}
        </a>
        -
        <a href="https://scholar.google.ca/citations?user=6Ist2oUAAAAJ&hl=en">
          Scholar
          {/*<i class="ai ai-google-scholar-square ai-2x"></i>*/}
        </a>
        -
        <a href="https://orcid.org/0000-0002-7931-8921">
          {/*<i class="fab fa-orcid fa-2x"></i>*/}
          Orcid
        </a>
        -
        <a href="https://github.com/fcharih">
          {/*<i class="fab fa-github fa-2x"></i>*/}
          GitHub
        </a>
        {/*-*/}
        {/*<a href="https://www.instagram.com/francois.charih/">
          Instagram
        </a>
        -
        <a href="https://www.threads.net/@francois.charih">
          <i class="fab fa-goodreads fa-2x"></i>
          Threads
      </a>*/}
      </div>
      <hr className={styles.line} />

      <div className={styles.newsContainerWrapperLol}>
        <h2>News</h2>
        <div className={styles.newsContainer}>
          {NewsYAML.filter((news, i) => i < numNews).map((news, i) => (
            <News news={news} lang={pageLang} />
          ))}
        </div>
        <div
          className={styles.moreNews}
          style={{
            display: `${numNews >= NewsYAML.length ? "none" : "block"}`,
          }}
        >
          <div style={show("en")}>
            <a onClick={() => setNumNews(numNews + 4)}>More news</a>
          </div>
          <div style={show("fr")}>
            <a onClick={() => setNumNews(numNews + 4)}>Plus de nouvelles</a>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default IndexPage;
